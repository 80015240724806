import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export const Contacts = ({ slice }) => {
  const {title, logo, qr_code, text, cover_img, form_title, form_url} = slice.primary

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/website-forms";

    setLoading(true);
    const r = await callHook({url, uid:"mtv-bc-contact-form", params:data});
    //console.log(r);
    if (r && r.success === true) {
      setError(false);
    } else {
      setError(true);
    }
    setSend(true);
    setLoading(false);
  }

  const SimpleContactForm = ({ }) => {

    if (isLoading && !isSend) {
      return (
        <div className="text-start">
          <div className="loader"></div>
        </div>
      );
    }

    if (isSend) {
      return (
        <div className="text-start">
          {!isError && (
          <>
            <h5>Danke!</h5>
            <p>Wir werden Sie in Kürze kontaktieren.</p>
          </>
          )}
          {isError && (
          <>
            <h5>Es ist ein Fehler aufgetreten</h5>
            <p>Bitte versuchen Sie es später erneut.</p>
          </>
          )}
          <div className="col-auto">
            <button onClick={()=>setSend(false)} className={"btn text-uppercase btn-primary"}>{isError ? "Erneut versuchen" : "Wiederholen"}</button>
          </div>
        </div>
      );
    }

    return (
      <div className="">
        <h5 className="mb-3" >{form_title}</h5>
        <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input
              placeholder="Ihr Name"
              className={'form-control'}
              {...register('name', {required: false})}
            />
          </div>
          <div className={"mt-4"}>
            <input
              placeholder="Ihr Telefon"
              className={'form-control'}
              {...register('phone', {required: false})}
            />
          </div>
          <div className={"mt-4"}>
            <input
              placeholder="Ihre E-Mail-Adresse"
              className={"form-control"}
              required
              {...register('email', {required: true})}
            />
          </div>
          <div className={"mt-4"}>
            <textarea
              placeholder="Geben Sie hier Ihre Nachricht ein"
              type="text"
              rows="5"
              className={"form-control"}
              required
              {...register('text', {required: true})}
            />
          </div>
          <input type="submit" value="Nachricht senden" className={"mt-4 btn btn-primary"}/>
        </form>
		  </div>
    )
  }

  return (
    <div className={"py-4 "}>
      <div className="container py-5">
        <h4 className="" >{title}</h4>
        <div className="row gx-5 mt-5">
          <div className="col-md-6 col-lg-4">
            <PrismicRichText field={text.richText} />
            <div className="d-flex align-items-end">
              <img className="me-4" style={{objectFit:"contain"}} width="120" src={qr_code.url} alt={qr_code.alt} title={qr_code.alt}/>
              <img className="" style={{objectFit:"contain"}} width="132" src={logo.url} alt={logo.alt} title={logo.alt}/>
            </div>
          </div>
          <div className="col-md-6 col-lg-8 mt-5 mt-md-0">
            <SimpleContactForm/>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContacts on PrismicHomepageDataBodyContacts {
    primary {
      title
      text {
        richText
      }
      logo {
        url
        alt
      }
      qr_code {
        url
        alt
      }
      form_title
    }
  }
`
